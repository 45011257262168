<template>
  <div
    class="rr mt-1"
    :class="
      $route.name === 'view-operator'
        ? 'operator-table-width-v'
        : 'operator-table-width'
    "
  >
    <b-card no-body class="mb-0">
      <AllocateUnitForm
        :isCreate="true"
        :getAllocatedUnitsByOperators="getAllocatedUnitsByOperators"
      />
      <div class="table-responsive" v-if="show">
        <b-skeleton-table
          v-if="show"
          :rows="10"
          :columns="5"
          :table-props="{ bordered: true, striped: true }"
        />
      </div>
      <b-table
        v-if="!show"
        class="position-relative sim-icon-mb Operator-table"
        responsive
        show-empty
        align-v="end"
        :items="items"
        :fields="tableColumns"
        :empty-text="$t('NoMatchingRecordsFound')"
      >
        <template #cell(start_date)="row">
          <h6 class="mb-0 d-flex">
            {{
              `${
                row.item.start_date
                  ? formattedDates(`${row.item.start_date}`)
                  : " "
              }`
            }}
          </h6>
        </template>

        <template #cell(end_date)="row">
          <h6 class="mb-0 d-flex">
            {{
              `${
                row.item.end_date ? formattedDates(`${row.item.end_date}`) : " "
              }`
            }}
          </h6>
        </template>
        <template #cell(name)="row">
          {{ `${row.item.name ? `${row.item.name}` : " "}` }}
        </template>

        <template #cell(ACTIONS)="row">
          <div
            class="text-left track_tool"
            v-if="
              !(new Date(row.item.start_date) <= new Date()) ||
              !(new Date(row.item.end_date) <= new Date()) ||
              row.item.end_date === null
            "
          >
            <span :id="row.item.up_tool">
              <feather-icon
                v-b-tooltip.hover.v-primary
                :title="$t('tooTip.update')"
                icon="EditIcon"
                class="curserPointer text-primary action-icon"
                size="18"
                @click="editRow(row.item)"
              />
            </span>
            <span>
              <feather-icon
                v-b-tooltip.hover.v-primary
                :title="$t('tooTip.delete')"
                icon="Trash2Icon"
                class="text-danger action-icon curserPointer"
                size="18"
                v-b-modal.modal-sm-remove
                @click="deleteAllocatedUnit(row.item)"
              />
            </span>
          </div>
        </template>
      </b-table>
      <div class="mx-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            &nbsp;
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOperators"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>

      <b-modal
        v-model="unitAllocationShow"
        centered
        size="lg"
        no-close-on-esc
        no-close-on-backdrop
        :hide-header="true"
        modal-class="no-header-modal allocation-modal"
      >
        <b-card-text>
          <AllocateUnitForm
            :unitAllocationShow="unitAllocationShow"
            :onCloseUnitModal="onCloseUnitModal"
            :allocationData="allocationData"
            :getAllocatedUnitsByOperators="getAllocatedUnitsByOperators"
          />
        </b-card-text>
      </b-modal>
    </b-card>
    <AreYouSureModal
      :data="removedAllocatedUnitData"
      :onClose="onClose"
      :removedUser="removeUnitAllocation"
    />
  </div>
</template>
<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BPagination,
  VBTooltip,
  BSkeletonTable,
  BButton,
  BFormGroup,
  BCardText
} from "bootstrap-vue";

import AreYouSureModal from "@/layouts/components/AreYouSureModal.vue";
import UnitService from "@/libs/api/unit-service";
import Ripple from "vue-ripple-directive";
import Loader from "@/layouts/components/Loader.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import DynamicBadge from "@/layouts/components/DynamicBadge.vue";
import vSelect from "vue-select";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// import flatPickr from "vue-flatpickr-component";
import AllocateUnitForm from "./AllocateUnitForm.vue";
import * as moment from "moment";
import constants from "@/utils/constants";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BMedia,
    BAvatar,
    Loader,
    BLink,
    BBadge,
    BPagination,
    AreYouSureModal,
    VBTooltip,
    BSkeletonTable,
    DynamicBadge,
    vSelect,
    VBTooltip,
    BButton,
    BFormGroup,
    // flatPickr,
    ValidationProvider,
    ValidationObserver,
    BCardText,
    AllocateUnitForm
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple
  },
  data() {
    return {
      tableColumns: [
        {
          key: "start_date",
          tdClass: "",
          label: this.$t("allocatedTableColumns.startDate")
        },
        {
          key: "end_date",
          tdClass: "",
          label: this.$t("allocatedTableColumns.endDate")
        },
        {
          key: "name",
          tdClass: "",
          label: this.$t("allocatedTableColumns.name")
        }
      ],
      totalOperators: 0,
      currentPage: 1,
      items: [],
      removedAllocatedUnitData: {},
      show: false,
      unitList: [],
      start_date: null,
      end_date: null,
      name: "",
      perPage: 20,
      minTime: null,
      endDateDisabled: null,
      unitAllocationShow: false,
      allocationData: {}
    };
  },
  props: ["searchFiled", "groupName"],
  mounted() {
    if (this.$route.name === "edit-operator") {
      this.tableColumns.push({
        key: "ACTIONS",
        tdClass: "",
        thClass: "",
        label: this.$t("allocatedTableColumns.actions")
      });
    }
    this.tableColumns;
    this.getAllocatedUnitsByOperators();
  },

  methods: {
    formattedDates(date) {
      return this.formattedDateTime(date, constants.DATE_TIME_FORMAT);
    },
    onCloseUnitModal() {
      this.unitAllocationShow = false;
    },
    editRow(row) {
      this.allocationData = row;
      this.unitAllocationShow = true;
    },

    async getAllocatedUnitsByOperators() {
      try {
        this.show = true;
        const me = this;
        if (!this.filter || !this.filter.value) {
          this.filter = {};
        }

        let requestData = {
          id: this.$route.params.id,
          page: this.currentPage,
          page_size: parseInt(this.perPage),
          filters:
            this.filter && Object.keys(this.filter).length > 0
              ? [this.filter]
              : []
        };

        let response = await new UnitService().getAllocatedUnitsByOperators(
          requestData
        );
        if (response && response.data) {
          this.show = false;
          this.items = response.data.list || [];
          this.items = this.items.map((u) => {
            u.variant = this.getRandomBgColor();
            u.del_tool = `del${u.id}`;
            u.up_tool = `up${u.id}`;
            u.variant = this.getRandomBgColor();
            u.avtar_txt = this.userAvatarTxt(u.name);
            return u;
          });
          this.totalOperators =
            (response.data.pagination &&
              response.data.pagination.total_records) ||
            0;
        } else if (response && response.error && response.error.message) {
          this.show = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: response.error.title,
              text: response.error.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: err.message,
            icon: "InfoIcon",
            variant: "danger"
          }
        });
        this.show = false;
      }
    },

    deleteAllocatedUnit(props) {
      this.c1 = "src/layouts/components/AreYouSureModal.vue";
      this.status = true;
      this.removedAllocatedUnitData = { name: props.name, id: props.id };
    },

    onClose() {
      this.getAllocatedUnitsByOperators();
      this.$bvModal.hide("modal-sm-remove");
    },
    async removeUnitAllocation(props) {
      this.removedAllocatedUnitData = { name: "", id: "" };
      if (props.id) {
        try {
          this.onClose();
          let response = await new UnitService().removeUnitAllocation({
            id: this.$route.params.id,
            allocated_id: props.id
          });

          if (response && response.data) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$t("operators.OperatorUnitAllocationRemovedTitle"),
                text: this.$t("operators.OperatorUnitAllocationRemovedMsg"),
                icon: "EditIcon",
                variant: "success"
              }
            });

            this.getAllocatedUnitsByOperators();
          } else if (response && response.error && response.error.message) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: response.error.title,
                text: response.error.message,
                icon: "EditIcon",
                variant: "error"
              }
            });
          }
        } catch (err) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message,
              icon: "InfoIcon",
              variant: "danger"
            }
          });
        }
      }
    }
  }
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.alert-action-width {
  max-width: 130px;
  width: 130px;
  min-width: 130px;
}
.status-width {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
  text-align: center;
}

.badge-group {
  height: 15px;
  padding: 1px 9px 1px 9px;
  font-size: 9px;
  line-height: 11px;
}

/*.operator-table-width {
  margin-right: -20px;
  margin-left: -20px;
}*/
.flat-picker-date {
  display: flex;
  justify-content: center;
  align-items: center;
  .form-control {
    border-radius: 0.357rem 0rem 0rem 0.357rem;
  }
}
.flat-picker-icon {
  border-right: 1px solid var(--gray4);
  border-top: 1px solid var(--gray4);
  border-bottom: 1px solid var(--gray4);
  border-radius: 0rem 0.357rem 0.357rem 0rem;
  padding: 0.438rem 1rem;
}
.mt-15 {
  margin-top: 14px;
}

.allocation-modal {
  .header-area-button {
    height: auto;
    .select-unit-un {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .modal-dialog {
    max-width: 650px;
  }
}
@media only screen and (max-width: 991px) {
  .header-area-button {
    margin-bottom: 100px;
  }
}
.rr {
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
  padding: 10px;
  border-radius: 5px;
}
.dark-layout .rr {
  box-shadow: 0 4px 24px 0 #161d30;
}
.Operator-table {
  .b-table {
    th {
      text-align: start !important;
    }
  }
}
</style>
<style lang="scss" scoped>
.operator-table-width {
  .Operator-table {
    height: calc(100vh - 470px) !important;
  }
}
.operator-table-width-v {
  .Operator-table {
    height: calc(100vh - 570px) !important;
  }
}
</style>
