import { render, staticRenderFns } from "./AllocatedUnits.vue?vue&type=template&id=48b2d980&scoped=true&"
import script from "./AllocatedUnits.vue?vue&type=script&lang=js&"
export * from "./AllocatedUnits.vue?vue&type=script&lang=js&"
import style0 from "./AllocatedUnits.vue?vue&type=style&index=0&id=48b2d980&prod&lang=scss&"
import style1 from "./AllocatedUnits.vue?vue&type=style&index=1&id=48b2d980&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48b2d980",
  null
  
)

export default component.exports